import { createSlice } from "@reduxjs/toolkit";


const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: "",
    error: "",
    user: {},
    access: [],
    graphData: {},
    roles: []
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.token = action.payload;
    },
    loginFailed: (state, action) => {
      state.error = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAccess: (state, action) => {
      state.access = action.payload;
    },
    setGraphData: (state, action) => {
      state.graphData = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    }
  },
});

export const {loginSuccess, loginFailed, setUser, setAccess, setGraphData, setRoles} = authSlice.actions;
export default authSlice.reducer;