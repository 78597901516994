import React from 'react';
import PropTypes from 'prop-types';
import Loading from './Loading';
import ErrorComponent from './Error';
import _ from 'lodash';


class FormInput extends React.Component {
  constructor(props) {
    super(props);
    this.id = _.uniqueId("form-");
  }
  render() {
    const id = this.id;
    return (
      <div className="form-group mb-2">
        <div className="d-flex justify-content-between">
        <label htmlFor={id} className="form-label">{this.props.label}</label>
        <Loading show={this.props.loading} size="sm" displayStyle="inline" />
        </div>
        <input className="form-control" id={id} disabled={this.props.loading || this.props.disabled} onChange={(e) => this.props.onChange(e.target.value)} value={this.props.value || ''} /> 
        <small className="form-text text-muted">{this.props.helpText}</small>
        <ErrorComponent displayStyle="inline" error={this.props.error} />
      </div>
    )
  }
}

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  helpText: PropTypes.string,
  error: PropTypes.object
}

FormInput.defaultProps = {
  loading: false,
  disabled: false
}

export default FormInput;