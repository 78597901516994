import React from 'react';
import { MoonStars, Sun, CircleHalf } from 'react-bootstrap-icons';
import NavDropdown from 'react-bootstrap/NavDropdown';

class ThemeSwitcher extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentMode: 'auto'
    }
  }

  componentDidMount = () => {
    //Fetch mode from local storage
    const localTheme = localStorage.getItem('theme');
    if(localTheme) {
      this.setState({currentMode: localTheme})
    }
  }

  switchTheme = (theme) => {
    localStorage.setItem('theme', theme);
    this.setState({currentMode: theme})
    window.location.reload();
  }

  render() {
    return (
      <>
       <NavDropdown title={<MoonStars size="20" />} id="color-selector" align="end" className="m-2">
        <NavDropdown.Header>
          Colour Mode
        </NavDropdown.Header>
          <NavDropdown.Item active={(this.state.currentMode === 'light') ? true : false} onClick={() => this.switchTheme('light')}><Sun className="me-2" />Light</NavDropdown.Item>
          <NavDropdown.Item active={(this.state.currentMode === 'dark') ? true : false} onClick={() => this.switchTheme('dark')}><MoonStars className="me-2" />Dark</NavDropdown.Item>
          <NavDropdown.Item active={(this.state.currentMode === 'auto') ? true : false} onClick={() => this.switchTheme('auto')}><CircleHalf className="me-2" />Auto</NavDropdown.Item>
        </NavDropdown>
      </>
    )
  }
}

export default ThemeSwitcher;