import axios from 'axios';
import {api_url} from '../config';

export async function getGroups() {
  const response = await axios.get(api_url + '/api/groups')
  return response.data;
}

export async function getGroup(group_id) {
  const response = await axios.get(api_url + '/api/groups/' + group_id)
  return response.data;
}

export async function getGroupLCNs(group_id) {
  const response = await axios.get(api_url + '/api/groups/' + group_id + '/lcns')
  return response.data;
}
