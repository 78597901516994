import React from 'react';
import DataTable, {createTheme} from 'react-data-table-component';

createTheme('dark', {
  background: {
    default: 'transparent'
  },
  striped: {
    default: 'transparent'
  }
});


function DataTableBase(props) {
  let theme = localStorage.getItem('theme')
  if(!theme || theme === 'auto') {
    theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }

  return (
    <DataTable
      pagination
      dense
      highlightOnHover
      striped
      theme={theme}
      {...props}
    />
  )
}

export default DataTableBase;