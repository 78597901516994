import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false, error: null, errorInfo: null};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({error, errorInfo});
    //logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <div className="px-4 py-5 my-5 text-center">
          <h1 className="display-5 fw-bold">Something went wrong</h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">An error has occured processing your request.</p>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
              <a type="button" className="btn btn-primary btn-lg px-4 gap-3" href="/">Return Home</a>
            </div>
          </div>
        </div>
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;