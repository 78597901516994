import React from 'react';
import { withRouter } from 'react-router-dom'

import {Modal, Button} from 'react-bootstrap';
import Error from '../global/Error';
import Loading from '../global/Loading';
import DataTable from '../global/DataTableBase';

import {searchCustomer} from '../../api/customers';
import {searchService} from '../../api/services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import _ from 'lodash';


class SearchBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
      isLoading: false,
      searchMode: 'customer',
      searchTerm: '',
      error: null,
      data: []
    }
  }

  componentDidMount = () => {
    window.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.handleKeyPress);
  }

  handleKeyPress = (event) => {
    if(event.key === 'k' && (event.metaKey || event.ctrlKey)) {
      this.showSearch();
    }
  }

  showSearch = () => {
    this.setState({showSearch: true, searchTerm: '', data: [], searchMode: 'customer'});
  }

  hideSearch = () => {
    this.setState({showSearch: false});
  }

  search = async (searchInput) => {
    this.setState({isLoading: true, error: null});
    if(!searchInput || searchInput.length < 3) {
      this.setState({searchTerm: searchInput, isLoading: false, data: []});
    }
    if(searchInput.length >= 3) {
      this.setState({searchTerm: searchInput});
      this.executeSearch(searchInput);
    }
  };

  executeSearch = _.debounce(async (searchInput) => {
    try {
      let result = [];

      if(this.state.searchMode === 'customer') {
        result = await searchCustomer(searchInput);
      } else {
        result = await searchService(searchInput);
      }
      
      const sortedResults = _.sortBy(result, ['Name']);
      this.setState({data: sortedResults, isLoading: false});
    }
    catch(error) {
      this.setState({error, isLoading: false});
    }
    
  }, 500);

  gotoCustomer = (customerId) => {
    this.setState({showSearch: false, searchTerm: '', data: []});
    this.props.history.push('/customers/' + customerId);
  }

  gotoService = (serviceId) => {
    this.setState({showSearch: false, searchTerm: '', data: []});
    this.props.history.push('/services/' + serviceId);
  }

  renderCustomerTable = () => {
    const cols = [
      {
        name: 'ID',
        selector: row => row.CustomerID,
        cell: row => (<span className="link" onClick={() => this.gotoCustomer(row.CustomerID)}>{row.CustomerID}</span>),
        sortable: true,
        grow: 0.5
      },
      {
        name: 'Name',
        selector: row => row.Name,
        wrap: true,
        sortable: true,
        grow: 2
      },
      {
        name: 'Group',
        selector: row => row.Group,
        hide: 'md'
      },
      {
        name: 'Postcode',
        selector: row => row.Postcode,
        hide: 'md'
      }
    ];
    return (
      <DataTable keyField="CustomerID" columns={cols} data={this.state.data} dense={true} noDataComponent={(<div>No Results Found</div>)} />
    )
  }

  renderServiceTable = () => {
    const cols = [
      {
        name: 'Service ID',
        selector: row => {row.ServiceID.toString().padStart(5, '0')},
        cell: row => (<span className="link" onClick={() => this.gotoService(row.ServiceID)}>{row.ServiceID.toString().padStart(5, '0')}</span>),
        sortable: true,
        grow: 0.5
      },
      {
        name: 'Customer Name',
        selector: row => row.Name,
        wrap: true,
        sortable: true,
        grow: 2
      },
      {
        name: 'Service Type',
        selector: row => row.ServiceType,
        hide: 'md'
      },
      {
        name: 'Property',
        selector: row => row.Property,
        hide: 'md'
      },
      {
        name: 'Value',
        selector: row => row.Value,
        hide: 'md'
      }
    ];
    return (
      <DataTable keyField="ServicePropertyID" columns={cols} data={this.state.data} dense={true} noDataComponent={(<div>No Results Found</div>)} />
    )
  }

  switchMode = () => {
    if(this.state.searchMode === 'customer') {
      this.setState({searchMode: 'service', searchTerm: '', data: []})
    } else {
      this.setState({searchMode: 'customer', searchTerm: '', data: []})
    }
    this.input.focus();
  }

  render() {
    return (
      <>
        <div className="d-flex" onClick={this.showSearch}>
          <FontAwesomeIcon icon={faMagnifyingGlass} fixedWidth size="xl" className="me-2" />
        </div>

        <Modal centered show={this.state.showSearch} onHide={this.hideSearch} animation={false} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Search</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <ul className="nav nav-pills mb-4">
              <li className="nav-item" style={{cursor: 'pointer'}}><div className={`nav-link ${(this.state.searchMode === 'customer') ? 'active': ''}`} onClick={() => this.switchMode()}>Customer Search</div></li>
              <li className="nav-item" style={{cursor: 'pointer'}}><div className={`nav-link ${(this.state.searchMode === 'service') ? 'active': ''}`} onClick={() => this.switchMode()}>Service Search</div></li>
            </ul>
            <input 
              className="form-control mb-2" 
              name="search" 
              placeholder="Search"
              value={this.state.searchTerm}
              onChange={(e) => this.search(e.target.value)}
              ref={(input) => {this.input = input;}}
              autoFocus
            />
            <Error error={this.state.error} />
            {this.state.isLoading ? <Loading /> : undefined}
            {(this.state.isLoading === false && this.state.searchTerm !== '' && this.state.searchTerm.length >= 3 && this.state.searchMode === 'customer') ? this.renderCustomerTable() : undefined}
            {(this.state.isLoading === false && this.state.searchTerm !== '' && this.state.searchTerm.length >= 3 && this.state.searchMode === 'service') ? this.renderServiceTable() : undefined}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.hideSearch}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default withRouter(SearchBar);