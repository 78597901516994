import React from 'react';
import PropTypes from 'prop-types';


class Loading extends React.Component {
  render() {
    if(!this.props.show) {
      return null;
    }
    if(this.props.displayStyle === 'block') {
      return (
        <div className="d-flex justify-content-center">
          <div className={`spinner-border m-2 ${this.props.size === 'sm' ? 'spinner-border-sm' : ''}`} role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`spinner-border me-2 ${this.props.size === 'sm' ? 'spinner-border-sm' : ''}`} role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )
    }
    
  }
}

Loading.propTypes = {
  size: PropTypes.string,
  show: PropTypes.bool,
  displayStyle: PropTypes.oneOf(['inline', 'block'])
}

Loading.defaultProps = {
  size: '',
  show: true,
  displayStyle: 'block'
}

export default Loading;