import React from 'react';
import PropTypes from 'prop-types';

class ErrorComponent extends React.Component {
  render() {
    if(!this.props.error) {
      return null
    }
    let message = '';
    if(this.props.error.message) {
      message = this.props.error.message
    }

    if(this.props.error.response && this.props.error.response.data && this.props.error.response.data.message) {
      message = this.props.error.response.data.message
    }

    if(this.props.displayStyle === 'inline') {
      return (
        <div className="text-danger">{message}</div>
      )
    }
    
    return (
      <div className="container">
      <div className="alert alert-danger" role="alert">
        <strong>Error: </strong>{message}
      </div>
      </div>
    )
  }
}

ErrorComponent.propTypes = {
  error: PropTypes.object,
  displayStyle: PropTypes.oneOf(['alert', 'inline'])
}

ErrorComponent.defaultProps = {
  displayStyle: 'alert'
}

export default ErrorComponent;