import {msal_client_id, msal_redirect_uri} from './config';

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

// MSAL configuration
export const msalConfig = {
  auth: {
      clientId: msal_client_id,
      authority: "https://login.microsoftonline.com/3a4af158-b8a5-4bc8-833c-d973205f2bc2",
      redirectUri: msal_redirect_uri,
      postLogoutRedirectUri: "/"
  },
  cache: {
    cacheLocation: "sessionStorage"
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"]
};