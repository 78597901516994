import {msal_scope} from './config';

import React from 'react';
import { connect } from 'react-redux'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import axios from 'axios';

//Stylesheets
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

//Components
import ErrorHandler from './components/global/Error';
import Header from './components/global/Header';
import Footer from './components/global/Footer';
import Home from './components/Home';
import Loading from './components/global/Loading';
import ErrorBoundary from './ErrorBoundary';

//Providers
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { PublicClientApplication, EventType, InteractionType } from "@azure/msal-browser";
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import {loginRequest} from './authConfig';

//Data
import {getCurrentUser} from './api/auth';
import {setUser, setAccess, setGraphData, setRoles} from './slices/authSlice';

//Toast
import { ToastContainer } from 'react-toastify';

//Lazy load Components
const Customer = React.lazy(() => import('./components/Customer'));
const Service = React.lazy(() => import('./components/Service'));
const AssetHome = React.lazy(() => import('./components/AssetHome'));
const Asset = React.lazy(() => import('./components/Asset'));
const DevPortal = React.lazy(() => import('./components/DevPortal'));
const Configuration = React.lazy(() => import('./components/Configuration'));
const Reporting = React.lazy(() => import('./components/Reporting'));
const Tools = React.lazy(() => import('./components/Tools'));

//Redux
const mapDispatch = { setUser, setAccess, setGraphData, setRoles };
const mapStateToProps = state => ({
  auth: state.auth
});


const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    console.log('Login Success');
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
    window.appComponent.getAuthData();
  }
});


//Append auth header to API requests
axios.interceptors.request.use(async function(config) {
  const account = msalInstance.getActiveAccount();
  if(!account) {
    throw Error('Invalid Account');
  }

  const msalRequest = {
    scopes: [msal_scope],
    account
  }

  if(config.url.startsWith('https://graph.microsoft')) {
    msalRequest.scopes = ["User.Read"];
  }

  try {
    const response = await msalInstance.acquireTokenSilent(msalRequest);
    const token = `Bearer ${response.accessToken}`;
    config.headers = {
      'Authorization': token,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
  }
  catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      try {
        const response = await msalInstance.acquireTokenPopup(msalRequest);
        const token = `Bearer ${response.accessToken}`;
        config.headers = {
          'Authorization': token,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        };
      }
      catch(error) {
        console.error(error);
      }
    } else {
      console.error(error);
    }
  }
  
  return config;
})

//API response interceptor
axios.interceptors.response.use(function(response) {
  return response;
}, function(error) {
  return Promise.reject(error);
})


class App extends React.Component {

  constructor() {
    super();
    window.appComponent = this;
    this.state = {
      isLoading: true,
      error: null
    }
  }

  getAuthData = async () => {
    //Get and set authentication state
    if(msalInstance.getAllAccounts().length > 0) {
      //Fetch graph data
      //const graphData = await fetchGraphData();
      //this.props.setGraphData(graphData);
      const msalData = msalInstance.getActiveAccount();
      const userData = {
        RealName: msalData.name,
        Roles: msalData.idTokenClaims.roles,
        Username: msalData.username,
        Tenant: msalData.tenantId
      }

      console.log('Roles: ' + msalData.idTokenClaims.roles);

      //Fetch provisioning user data and update
      try {
        if(!(Array.isArray(msalData.idTokenClaims.roles)) || msalData.idTokenClaims.roles.length < 1) {
          throw new Error('Access denied. You do not have a valid role assigned for this application.');
        }
        const dbUser = await getCurrentUser();
        //userData['dbUser'] = dbUser;
        userData['AccessRights'] = dbUser['AccessRights'];
        this.props.setUser(userData);
        this.props.setAccess(dbUser['AccessRights']);
        this.props.setRoles(userData.Roles);
        this.setState({isLoading: false});
      }
      catch (error) {
        this.setState({error: error, isLoading: false});
      }
      
    }
  }

  processDarkMode = () => {
    const getTheme = () => localStorage.getItem('theme');

    const getPreferredTheme = () => {
      const storedTheme = getTheme();
      if(storedTheme) {
        return storedTheme;
      }

      return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }

    const setTheme = theme => {
      if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.documentElement.setAttribute('data-bs-theme', 'dark')
      } else {
        document.documentElement.setAttribute('data-bs-theme', theme)
      }
    }

    setTheme(getPreferredTheme());
    /*
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark');
    }
    else {
      document.documentElement.classList.remove('dark');
    }
    */
  }

  async componentDidMount() {
    await this.getAuthData();
    this.processDarkMode();
  }

  render() {
    return (
      <>
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate 
          interactionType={InteractionType.Redirect} 
          authenticationRequest={{...loginRequest}} 
          errorComponent={ErrorHandler}
        >
          <ErrorBoundary>
          <ErrorHandler error={this.state.error} />
          {(!this.state.isLoading && !this.state.error) ?
          <Router>
          <React.Suspense fallback={<div className="d-flex justify-content-center my-4"><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></div>}>
            <Header />
            <ToastContainer autoClose="3000" position="top-right" hideProgressBar={true} closeOnClick={true} />
            <Switch>
              <Route path="/customers/:id" children={<Customer />}></Route>
              <Route path="/services/:id" children={<Service />}></Route>
              <Route path="/assets/:id" children={<Asset />}></Route>
              <Route path="/assets" children={<AssetHome />}></Route>
              <Route path="/config"><Configuration /></Route>
              <Route path="/devportal"><DevPortal /></Route>
              <Route path="/reports"><Reporting /></Route>
              <Route path="/tools"><Tools /></Route>
              <Route exact path="/"><Home /></Route>
              <Route path="/"><Home /></Route>
            </Switch>
            <Footer />
          </React.Suspense>
          </Router>
          :
          <Loading show={this.state.isLoading} />
          }
          </ErrorBoundary>
        </MsalAuthenticationTemplate>
      </MsalProvider>
      </>
    );
  }
}



export default connect(mapStateToProps, mapDispatch)(App);
