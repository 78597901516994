import axios from 'axios';
import {api_url} from '../config';

export async function getCustomer(customerId) {
  const response = await axios.get(api_url + '/api/customers/' + customerId)
  return response.data;
}

export async function updateCustomer(customerId, field, value) {
  const response = await axios.post(api_url + '/api/customers/' + customerId, { [field]: value });
  return response.data;
}

export async function addCustomer(customerId, name, postcode, contract, location, group) {
  const response = await axios.post(api_url + '/api/customers', 
  {  
    CustomerID: customerId,
    Name: name,
    Postcode: postcode,
    ContractID: contract,
    LocationID: location,
    GroupID: group
  });
  return response.data;
}

export async function searchCustomer(searchTerm) {
  const response = await axios.get(api_url + '/api/customers/search', {params: {
    searchTerm
  }})
  return response.data;
}

export async function getCustomerServices(customerId) {
  const response = await axios.get(api_url + '/api/customers/' + customerId + '/services')
  return response.data;
}

export async function getCustomerAssets(customerId) {
  const response = await axios.get(api_url + '/api/customers/' + customerId + '/assets')
  return response.data;
}

export async function getCustomerProperties(customerId) {
  const response = await axios.get(api_url + '/api/customers/' + customerId + '/properties')
  return response.data;
}

export async function getCustomerSurveys(customerId) {
  const response = await axios.get(api_url + '/api/customers/' + customerId + '/surveys')
  return response.data;
}

export async function setCustomerProperty(customerId, propertyId, value) {
  const response = await axios.post(api_url + '/api/customers/' + customerId + '/properties', { PropertyID: propertyId, Value: value });
  return response.data;
}

export async function allocateCustomerProperty(customerId, propertyId) {
  const response = await axios.post(api_url + '/api/customers/' + customerId + '/properties/allocate', { PropertyID: propertyId });
  return response.data;
}

export async function unallocateCustomerProperty(customerId, propertyId) {
  const response = await axios.post(api_url + '/api/customers/' + customerId + '/properties/unallocate', { PropertyID: propertyId });
  return response.data;
}

export async function getCustomerNetworks(customerId) {
  const response = await axios.get(api_url + '/api/customers/' + customerId + '/networks')
  return response.data;
}

export async function syncCustomerNetworks(customerId) {
  const response = await axios.get(api_url + '/api/customers/' + customerId + '/networks/sync')
  return response.data;
}

export async function getCustomerSpeedTests(customerId) {
  const response = await axios.get(api_url + '/api/customers/' + customerId + '/speedtests')
  return response.data;
}

export async function getCustomerNotes(customerId) {
  const response = await axios.get(api_url + '/api/customers/' + customerId + '/notes')
  return response.data;
}

export async function getLogs(customerId, abortController) {
  const response = await axios.get(api_url + '/api/customers/' + customerId + '/logs',
  {
    signal: abortController.signal
  })
  return response.data;
}