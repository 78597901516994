import React from 'react';
import { connect } from 'react-redux'

//Boostrap Components
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {withRouter, NavLink} from 'react-router-dom';

//Images
import RMLogo from '../../img/rm_logo.svg';

//Icons
import { Building, Search, BoxSeam, GraphUp, Gear, Tools } from 'react-bootstrap-icons';

//Menu Components
import SearchBar from './SearchBar';
import ThemeSwitcher from './ThemeSwitcher';

const mapStateToProps = state => ({
  auth: state.auth
});

class Header extends React.Component {


  render() {
    //const displayName = this.props.auth.user.RealName;
    //const department = this.props.auth.user.Username;
    return (
      <>
      <Navbar className="py-0 mb-2 px-2" expand="md">
        <Navbar.Brand href="/" className="brand">
          <img 
            src={RMLogo} 
            alt="RM Logo" 
            className="d-inline-block align-top"
            width="50"
            height="50"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="me-auto">
        <Nav className="me-auto">
          <NavLink to="/" className="nav-link" activeClassName="active" isActive={(match, location) => {
              if(location.pathname.substring(0, 10) === '/customers' || location.pathname.length === 1) {
                return true;
              } else {
                return false;
              }
            }
          }>
            <Building className="me-2"/>Customers
          </NavLink>
          <NavLink to="/search" className="nav-link disabled" activeClassName="active"><Search className="me-2" />Search</NavLink>
          <NavLink to="/assets" className="nav-link" activeClassName="active"><BoxSeam className="me-2" />Assets</NavLink>
          <NavLink to="/reports" className="nav-link" activeClassName="active"><GraphUp className="me-2" />Reporting</NavLink>
          <NavLink to="/tools" className="nav-link" activeClassName="active"><Tools className="me-2" />Tools</NavLink>
          {(this.props.auth?.roles.includes('Dev')) ? <NavLink to="/config" className="nav-link" activeClassName="active"><Gear className="me-2" />Configuration</NavLink> : undefined }
        </Nav>
          <SearchBar />
          <ThemeSwitcher />
        </Navbar.Collapse>
      </Navbar>
      </>
    )
  }
  
}

export default connect(mapStateToProps)(withRouter(Header));;