import axios from 'axios';
import {api_url} from '../config';

export async function getService(serviceId) {
  const response = await axios.get(api_url + '/api/services/' + serviceId)
  return response.data;
}

export async function updateService(serviceId, field, value) {
  const response = await axios.post(api_url + '/api/services/' + serviceId, { [field]: value });
  return response.data;
}

export async function addService(customerId, serviceType) {
  const response = await axios.post(api_url + '/api/services' , 
  { 
    CustomerID: customerId,
    ServiceTypeID: serviceType
   });
  return response.data;
}

export async function searchService(searchTerm) {
  const response = await axios.get(api_url + '/api/services/search', {params: {
    searchTerm
  }})
  return response.data;
}


export async function getServiceProperties(serviceId) {
  const response = await axios.get(api_url + '/api/services/' + serviceId + '/properties')
  return response.data;
}

export async function setServiceProperty(serviceId, propertyId, value) {
  const response = await axios.post(api_url + '/api/services/' + serviceId + '/properties', { PropertyID: propertyId, Value: value });
  return response.data;
}

export async function allocateServiceProperty(serviceId, propertyId) {
  const response = await axios.post(api_url + '/api/services/' + serviceId + '/properties/allocate', { PropertyID: propertyId });
  return response.data;
}

export async function unallocateServiceProperty(serviceId, propertyId) {
  const response = await axios.post(api_url + '/api/services/' + serviceId + '/properties/unallocate', { PropertyID: propertyId });
  return response.data;
}

export async function getMonitoring(serviceId, abortController = new AbortController()) {
  const response = await axios.get(api_url + '/api/services/' + serviceId + '/monitoring',
  {
    signal: abortController.signal
  });
  return response.data;
}

export async function scheduleMonitoringCheck(serviceId, service) {
  const response = await axios.post(api_url + '/api/services/' + serviceId + '/monitoring', {Service: service});
  return response.data;
}

export async function getServiceInterfaces(serviceId) {
  const response = await axios.get(api_url + '/api/services/' + serviceId + '/interfaces')
  return response.data;
}

export async function getServiceAssets(serviceId) {
  const response = await axios.get(api_url + '/api/services/' + serviceId + '/assets')
  return response.data;
}

export async function getServiceInterconnects(serviceId) {
  const response = await axios.get(api_url + '/api/services/' + serviceId + '/interconnects')
  return response.data;
}

export async function addInterconnect(serviceId, interconnectId) {
  const response = await axios.post(api_url + '/api/services/' + serviceId + '/interconnects', { InterconnectID: interconnectId });
  return response.data;
}

export async function deleteInterconnect(serviceId, serviceInterconnectId) {
  const response = await axios.delete(api_url + '/api/services/' + serviceId + '/interconnects/' + serviceInterconnectId);
  return response.data;
}

export async function getServiceVrfs(serviceId) {
  const response = await axios.get(api_url + '/api/services/' + serviceId + '/vrfs')
  return response.data;
}

export async function getServiceCommands(serviceId) {
  const response = await axios.get(api_url + '/api/services/' + serviceId + '/commands')
  return response.data;
}

export async function runServiceCommand(serviceId, commandId) {
  const response = await axios.post(api_url + '/api/services/' + serviceId + '/commands/' + commandId)
  return response.data;
}

export async function getServiceCommandsHistory(serviceId, abortController) {
  const response = await axios.get(api_url + '/api/services/' + serviceId + '/commands/history',
  {
    signal: abortController.signal
  })
  return response.data;
}

export async function getServiceOutages(serviceId) {
  const response = await axios.get(api_url + '/api/services/' + serviceId + '/outages')
  return response.data;
}

export async function getServiceTemplates(serviceId) {
  const response = await axios.get(api_url + '/api/services/' + serviceId + '/templates')
  return response.data;
}

export async function getServiceDeployments(serviceId) {
  const response = await axios.get(api_url + '/api/services/' + serviceId + '/deployments')
  return response.data;
}

export async function getServiceNotes(serviceId) {
  const response = await axios.get(api_url + '/api/services/' + serviceId + '/notes')
  return response.data;
}

export async function getServiceNetworks(serviceId) {
  const response = await axios.get(api_url + '/api/services/' + serviceId + '/networks')
  return response.data;
}

export async function getServiceUsage(serviceId) {
  const response = await axios.get(api_url + '/api/services/' + serviceId + '/usage')
  return response.data;
}

export async function getServiceUsageMonth(serviceId) {
  const response = await axios.get(api_url + '/api/services/' + serviceId + '/usage/month')
  return response.data;
}

export async function getRadiusLogs(serviceId) {
  const response = await axios.get(api_url + '/api/services/' + serviceId + '/logs/radius')
  return response.data;
}