import React from 'react';
import {Modal} from 'react-bootstrap';
import ErrorComponent from '../global/Error';
import Loading from '../global/Loading';
import FormSelect from '../global/FormSelect';
import FormInput from '../global/FormInput';

import {addCustomer} from '../../api/customers';
import {getContracts} from '../../api/contracts';
import {getLocations} from '../../api/locations';
import {getGroups} from '../../api/groups';

class AddCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isSaving: false,
      error: null,
      customerId: null,
      name: null,
      postcode: null,
      location: null,
      contract: null,
      group: null,
      locations: [],
      contracts: [],
      groups: []
    }
  }

  componentDidMount = () => {
    this.loadData();
  }

  loadData = async () => {
    const contracts = await getContracts();
    const locations = await getLocations();
    const groups = await getGroups();
    this.setState({contracts, locations, groups, isLoading: false});
  }

  addCustomer = async () => {
    try {
      this.setState({isSaving: true, error: null});
      const customerId = this.state.customerId;
      if(!this.state.customerId) {
        throw Error('Missing Customer ID');
      }
      if(!this.state.name) {
        throw Error('Missing Customer Name');
      }
      if(!this.state.postcode) {
        throw Error('Missing Postcode');
      }
      if(!this.state.contract) {
        throw Error('Missing Contract');
      }
      if(!this.state.location) {
        throw Error('Missing Location');
      }
      if(!this.state.group) {
        throw Error('Missing Customer Group');
      }
      await addCustomer(this.state.customerId, this.state.name, this.state.postcode, this.state.contract, this.state.location, this.state.group);
      this.setState({isSaving: false, customerId: null, name: null, postcode: null, contract: null, location: null, group: null});
      this.props.refresh(customerId);
    }
    catch(error) {
      this.setState({error, isSaving: false});
    }
  }

  hideModal = () => {
    this.setState({name: null, physicalPort: null});
    this.props.hide();
  }

  render() {
    return (
      <div>
      <Modal transition="false" centered show={this.props.show} onHide={this.hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ErrorComponent error={this.state.error} />
          <form>
          <FormInput value={this.state.customerId} onChange={(value) => this.setState({customerId: value})} label="Customer ID" helpText="Enter the RM customer ID" />
          <FormInput value={this.state.name} onChange={(value) => this.setState({name: value})} label="Customer Name" helpText="" />
          <FormInput value={this.state.postcode} onChange={(value) => this.setState({postcode: value})} label="Postcode" helpText="" />
          <FormSelect value={this.state.contract} onChange={(value) => this.setState({contract: value})} loading={this.state.isLoading} options={this.state.contracts} optKey="ContractID" optValue="Name" label="Contract" placeHolder="Select a contract" helpText="" />
          <FormSelect value={this.state.location} onChange={(value) => this.setState({location: value})} loading={this.state.isLoading} options={this.state.locations} optKey="LocationID" optValue="Name" label="Location" placeHolder="Select a location" helpText="" />
          <FormSelect value={this.state.group} onChange={(value) => this.setState({group: value})} loading={this.state.isLoading} options={this.state.groups} optKey="GroupID" optValue="Name" label="Group" placeHolder="Select a customer group" helpText="" />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={() => this.addCustomer()} disabled={this.state.isSaving}><Loading displayStyle="inline" size="sm" show={this.state.isSaving} /> Add Customer</button>
          <button className="btn btn-secondary" onClick={this.props.hide} disabled={this.state.isSaving}>Cancel</button>
        </Modal.Footer>
      </Modal>
      </div>
    )
  }
}

export default AddCustomer;