import React from 'react';
import { connect } from 'react-redux'

//Boostrap Components
import {withRouter} from 'react-router-dom';

const mapStateToProps = state => ({
  auth: state.auth
});

class Footer extends React.Component {


  render() {
    return (
      <>
        <div className="d-flex justify-content-between border-top p-2">
          <div className="fw-light fs-6 text-muted"></div>
          <div className="fw-light fs-6 text-muted">&copy; RM Education 2023</div>
        </div>
      </>
    )
  }
  
}

export default connect(mapStateToProps)(withRouter(Footer));;