import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import _ from 'lodash';
import { MsalContext } from "@azure/msal-react";
import {NavLink} from 'react-router-dom';

//Global Components
import Error from './global/Error';
import Loading from './global/Loading';
import DataTable from './global/DataTableBase';

//API
import {searchCustomer} from '../api/customers';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import AddCustomer from './customers/AddCustomer';

const mapStateToProps = state => ({
  auth: state.auth
});


class Home extends React.Component {
  static contextType = MsalContext;

  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      isLoading: false,
      searchTerm: '',
      showAdd: false,
      error: null
    };
  }

  callLogout = () => {
    const msalInstance = this.context.instance;
    msalInstance.logout();
  }

  search = async (searchInput) => {
    this.setState({isLoading: true, error: null});
    if(!searchInput || searchInput.length < 3) {
      this.setState({searchTerm: searchInput, isLoading: false, customers: []});
    }
    if(searchInput.length >= 3) {
      this.setState({searchTerm: searchInput});
      this.executeSearch(searchInput);
    }
  };

  executeSearch = _.debounce(async (searchInput) => {
    try {
      const result = await searchCustomer(searchInput);
      this.setState({customers: result, isLoading: false});
    }
    catch(error) {
      this.setState({error, isLoading: false});
    }
    
  }, 500);

  renderEmptyList = () => {
    if(!this.state.searchTerm) {
      return (
        <tr>
          <td colSpan="5">Please enter a search term...</td>
        </tr>
      )
    }
    return (
      <tr>
        <td colSpan="5">No results found.</td>
      </tr>
    )
  }

  renderCustomerTable = () => {
    const cols = [
      {
        name: 'Customer ID',
        selector: row => row.CustomerID,
        cell: row => (<NavLink to={'/customers/' + row.CustomerID} className="link">{row.CustomerID}</NavLink>),
        sortable: true,
        grow: 0.5
      },
      {
        name: 'Name',
        selector: row => row.Name,
        wrap: true,
        sortable: true,
        grow: 2
      },
      {
        name: 'Group',
        selector: row => row.Group,
        sortable: true,
        hide: 'md'
      },
      {
        name: 'Postcode',
        selector: row => row.Postcode,
        hide: 'md'
      }
    ];
    return (
      <DataTable keyField="CustomerID" paginationPerPage={20} columns={cols} data={this.state.customers} dense={true} noDataComponent={(<div>No results found.</div>)} />
    )
  }

  navigateToCustomer = (customerId) => {
    this.props.history.push('/customers/' + customerId);
  }

  render() {
    return (
      <div className="container-fluid">
        {(this.state.showAdd) ? <AddCustomer show={this.state.showAdd} hide={() => this.setState({showAdd: false})} refresh={(customerId) => this.navigateToCustomer(customerId)} /> : undefined }
        <div className="row justify-content-end">
          <div className="col-4 text-end">
            <div><button className="btn btn-secondary btn-sm my-2" onClick={() => this.setState({showAdd: true})}><FontAwesomeIcon icon={faUserPlus} fixedWidth className="me-2" />Add Customer</button></div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="p-5 mb-4 rounded-3 border bg-body-tertiary">
              <div className="container-fluid py-3">
                <h1 className="display-5 fw-bold">RM Connectivity</h1>
                <p className="lead">Customer Search</p>
                <div className="form-group">
                  <label htmlFor="customerSearch" className="visually-hidden">Find a Customer</label>
                  <div className="input-group mb-2">
                    <div className="input-group-text"><FontAwesomeIcon icon={faMagnifyingGlass} fixedWidth /></div>
                    <input type="text" className="form-control" id="customerSearch" value={this.state.searchTerm} onChange={(e) => this.search(e.target.value)} placeholder="Search for a customer" autoFocus/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-1">
          <div className="col-12">
            <Error error={this.state.error} />
            {this.state.isLoading ? <Loading /> : undefined}
            {(!this.state.isLoading && this.state.searchTerm.length >= 3) ? this.renderCustomerTable() : undefined}
          </div>
        </div>
      </div>
    )
  }
  
}

export default withRouter(connect(mapStateToProps)(Home));