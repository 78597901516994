import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import authSlice from './slices/authSlice';
import counterSlice from './slices/counterSlice';

const middleware = [
  ...getDefaultMiddleware(),
  /*YOUR CUSTOM MIDDLEWARES HERE*/
];

const store = configureStore({
  reducer: {
    auth: authSlice,
    counter: counterSlice,
  },
  middleware,
});

export default store;